<template>
  <div class="NewsView">
    NewsView
  </div>
</template>

<script>
export default {
  name: 'NewsView',
  data() {
    return {
      msg: 'Welcome to Yosur Vue.js App',
    };
  },
};
</script>
